<template>
  <div v-if="getUser.role.slug === 'super-administrateur' || getUser.role.slug === 'assistante-administrative'">
    <b-card no-body>
      <b-card-header class="pb-50">
        <h5>Filtrages</h5>
      </b-card-header>
      <b-card-body>
        <b-row>
          <b-col
            cols="12"
            md="10"
            lg="10"
            class="mb-md-0 mb-2"
          >
            <b-row>
              <b-col
                cols="12"
                sm="6"
                md="6"
                lg="3"
              >
                <label for="service">Employé</label>
                <v-select
                  v-model="payloadFilter.employee_id"
                  :options="employeeOptions"
                  class="invoice-filter-select"
                  label="name"
                  :reduce="(employee) => employee.id"
                >
                  <template v-slot:no-options>
                    <b-spinner
                      v-if="isEmployeesWithoutPaginationLoading"
                      style="width: 2.5rem; height: 2.5rem"
                      class="align-middle text-info"
                    />
                    <span
                      v-else
                      class="mt-1 font-medium-1"
                    >Aucun employé ne correspond</span>
                  </template>
                </v-select>
              </b-col>
              <b-col
                cols="12"
                sm="6"
                md="4"
                lg="3"
                class="mt-2"
              >
                <b-button
                  variant="primary"
                  @click="applyFilterEmployeePaymentAction()"
                >
                  <div v-if="isFilterEmployeePayment">
                    <span> Chargement ... </span>
                    <b-spinner small />
                  </div>
                  <span
                    v-else
                    class="text-nowrap font-medium-1"
                  >Filtrer</span>
                </b-button>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </b-card-body>
    </b-card>
    <b-card title="Portefeuille des employées">
      <b-row>
        <b-col
          cols="12"
          md="3"
          class="d-flex align-items-center justify-content-start mb-2"
        >
          <b-button
            variant="primary"
            @click="reLoadDataEmployeesWalletAction()"
          >
            <div v-if="isReLoadDataEmployeesWallet">
              <span> Chargement ... </span>
              <b-spinner small />
            </div>
            <span
              v-else
              class="text-nowrap font-medium-1"
            >Actualiser</span>
          </b-button>
        </b-col>
        <b-col
          cols="12"
          md="4"
        >
          <v-select
            v-model="soldeFilter"
            :options="soldeOptions"
            class="invoice-filter-select"
            placeholder="Filtrer par solde"
            :reduce="(item) => item.value"
          >
            <template #selected-option="{ label }">
              <span class="text-truncate overflow-hidden">
                {{ label }}
              </span>
            </template>
          </v-select>
        </b-col>
      </b-row>

      <vue-good-table
        :columns="columns"
        :rows="rows"
        :is-loading="isEmployeesWalletLoading"
      >
        <template slot="emptystate">
          <div class="text-center">
            <span> {{ isEmployeesWalletLoading ? "" : "Liste vide" }} </span>
          </div>
        </template>

        <template slot="loadingContent">
          <div class="text-center text-info">
            <b-spinner class="align-middle" /> &nbsp;
            <span class="align-middle">Chargement... </span>
          </div>
        </template>

        <template
          slot="table-row"
          slot-scope="props"
        >
          <div
            v-if="props.column.field === 'full_name'"
            class="text-nowrap text-center align-middle"
          >
            <span class="d-block text-nowrap text-info font-small-5">{{
              props.row.full_name
            }}</span>
          </div>

          <div
            v-else-if="props.column.field === 'balance'"
            class="text-nowrap text-center align-middle"
          >
            <span
              v-if="props.row.balance"
              class="d-block text-nowrap text-info font-small-5"
            >
              <span
                v-if="props.row.balance > 0"
                class="text-success"
              >
                {{ props.row.balance }} XOF
              </span>
              <span
                v-else-if="props.row.balance < 0"
                class="text-danger"
              >
                {{ props.row.balance }} XOF
              </span>
            </span>
            <span
              v-else
              class="d-block text-nowrap text-info font-small-5"
            >
              0 FCFA
            </span>
          </div>

          <div
            v-else-if="props.column.field === 'action'"
            class="align-middle text-center text-info"
          >
            <span>
              <b-dropdown
                variant="link"
                toggle-class="text-decoration-none"
                no-caret
              >
                <template v-slot:button-content>
                  <feather-icon
                    icon="MoreVerticalIcon"
                    size="20"
                    class="text-info align-middle mr-25"
                  />
                </template>
                
                <b-dropdown-item
                  v-if="getUser.role.slug === 'super-administrateur' || getUser.role.slug === 'assistante-administrative'"
                  :id="`details${props.row.id}`"
                  @click="showEmployeeWalletDetails(props.row)"
                >
                  <feather-icon
                    icon="EyeIcon"
                    class="cursor-pointer text-info mr-1"
                    size="25"
                  />
                  <span>Voir le portefeuille employé</span>
                </b-dropdown-item>
              </b-dropdown>
            </span>
          </div>
        </template>
      </vue-good-table>

      <div class="d-flex justify-content-between mt-2 flex-wrap">
        <b-button
          :disabled="metaData.prev_page_url == null"
          variant="outline-secondary"
          @click="applyLoadMoreEmployeesWalletAction(metaData.prev_page_url)"
        >
          <span class="text-white text-nowrap font-medium-2">Page précédente</span>
        </b-button>

        <div>
          <span class="text-white font-medium-2">{{ metaData.current_page }} sur
            {{ metaData.last_page }} pages</span>
        </div>
        <div>
          <span class="text-white font-medium-2">Total : {{ total }}</span>
        </div>
        <b-button
          :disabled="metaData.next_page_url == null"
          variant="outline-secondary"
          @click="applyLoadMoreEmployeesWalletAction(metaData.next_page_url)"
        >
          <span class="text-nowrap font-medium-1 text-white">Page suivante</span>
        </b-button>
      </div>
    </b-card>

    <!-- modal de téléchargement de la fiche de paie des employés -->
    <b-modal
      id="download-employee-payslip"
      cancel-variant="outline-secondary"
      hide-footer
      centered
      no-close-on-backdrop
      no-close-on-esc
      title="Téléchargement de la fiche de paie des employés."
    >
      <validation-observer
        #default="{}"
        ref="downloadFile"
      >
        <!-- Form -->
        <b-form
          class="p-2"
          autocomplete="off"
          @reset.prevent="hideModalPayslip()"
          @submit.prevent="applyDownloadPayslip()"
        >
          <validation-provider
            #default="{ errors }"
            name="month"
            rules="required"
          >
            <b-form-group
              label="Mois de la prestation à payer"
              label-for="month"
              :state="errors.length > 0 ? false : null"
            >
              <v-select
                v-model="payslipPaylaod.month"
                :options="monthOptions"
                label="text"
                :reduce="(item) => item.value"
              />
              <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                {{ errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

            <b-form-group
                  label="Année"
                  label-for="year"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="year"
                    rules="required"
                  >
                  <v-select
                      id="year"
                      v-model="payslipPaylaod.year"
                      :options="yearOptions"
                      :reduce="(item) => item.value"
                      
                    >
                      <span
                        slot="no-options"
                        @click="$refs.select.open = false"
                      >
                        Chargement...
                      </span>
                    </v-select>
                    <!-- <b-form-select
                      id="month"
                      v-model="payload.month"
                      :options="monthOptions"
                      multiple
                    /> -->
                    
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
            </b-form-group>

          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button
              :disabled="isPayslipLoading"
              variant="primary"
              class="mr-2"
              type="submit"
            >
              <div v-if="isPayslipLoading">
                <span> Chargement ... </span>
                <b-spinner small />
              </div>
              <span v-else>Télécharger</span>
            </b-button>
            <b-button
              type="reset"
              variant="outline-secondary"
            >
              Annuler
            </b-button>
          </div>
        </b-form>
      </validation-observer>
    </b-modal>

  </div>
  <div v-else>
    <not-authorized />
  </div>
</template>

<script>
import {
  BTab,
  BTabs,
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BCardHeader,
  BCardBody,
  BTable,
  BMedia,
  BAvatar,
  BBadge,
  BPagination,
  BTooltip,
  BSpinner,
  BDropdown,
  BDropdownItem,
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import { avatarText } from '@core/utils/filter'
import vSelect from 'vue-select'

import { mapGetters, mapActions } from 'vuex'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BTab,
    BTabs,
    BCard,
    BCardHeader,
    BCardBody,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BTooltip,
    BSpinner,
    vSelect,
    VueGoodTable,
  },
  data() {
    return {
      isReLoadDataEmployeesWallet: false,
      rows: [],
      employeeOptions: [],
      soldeFilter: null,
      soldeOptions: [
        {
          label: 'Positif',
          value: 'ps',
        },
        {
          label: 'Négatif',
          value: 'nf',
        },
      ],
      payloadFilter: {
        employee_id: '',
      },
      columns: [
        {
          label: 'Employé',
          field: 'full_name',
          filterOptions: {
            enabled: true,
            placeholder: 'Rechercher par Employé',
            filterFn(data, filterString) {
              return data.toLowerCase().includes(filterString.toLowerCase())
            },
          },
        },
        {
          label: 'Solde du portefeuille',
          field: 'balance',
        },
        {
          label: 'Action',
          field: 'action',
        },
      ],

      isFilterEmployeePayment: false,
      avatarText,

      fields: {
        'Nom complet': 'Nom complet',
        Age: 'Age',
        Téléphone: 'Téléphone',
        'Adresse de résidence': 'Adresse de résidence',
        'Adresse mail': 'Adresse mail',
        'Services fournies': 'Services fournies',
        "Statut de l'employé": "Statut de l'employé",
      },
    }
  },
  computed: {
    ...mapGetters('professionals', [
      'getEmployeesWallet',
      'getEmployeesWithoutPagination',
    ]),
    ...mapGetters([
      'isEmployeesWalletLoading',
      'isEmployeesWithoutPaginationLoading',
    ]),
    ...mapGetters('auth', ['getUser']),

    metaData() {
      const meta = {
        prev_page_url: '',
        next_page_url: '',
        current_page: '',
        current_page_url: '',
        last_page: '',
        total: '',
      }
      if (this.getEmployeesWallet != null) {
        meta.prev_page_url = this.getEmployeesWallet.prev_page_url
        meta.next_page_url = this.getEmployeesWallet.next_page_url
        meta.current_page = this.getEmployeesWallet.current_page
        meta.last_page = this.getEmployeesWallet.last_page
        this.total = this.getEmployeesWallet.total
      }
      return meta
    },
  },

  watch: {
    soldeFilter(newVal, oldVal) {
      if (newVal !== null) {
        this.rows = []
        this.filterEmployeesWalletAction({ solde_filter: newVal })
          .then(response => {
            this.rows = this.getEmployeesWallet
            this.total = this.rows.length
          })
          .catch(error => {
            console.log(error)
          })
      } else {
        this.reLoadDataEmployeesWalletAction()
      }
    },
    getEmployeesWallet(newValue, oldValue) {
      if (oldValue.length !== 0 && oldValue.length !== newValue.length) {
        this.rows = this.getEmployeesWallet.data
      }
    },
    isEmployeesWalletLoading(val) {
      if (val === false) {
        this.rows = this.getEmployeesWallet.data
      }
    },
    isEmployeesWithoutPaginationLoading(val) {
      if (val === false) {
        this.getEmployeesWithoutPagination.forEach(element => {
          this.employeeOptions.push({
            id: element.id,
            name: element.full_name,
          })
        })
      }
    },
    payloadFilter: {
      handler(newValue, oldValue) {
        const keys = Object.keys(newValue)
        keys.forEach(key => {
          if (newValue[key] == null) {
            newValue[key] = ''
          }
        })
      },
      deep: true,
    },
  },

  mounted() {
    if (this.getEmployeesWithoutPagination.length != 0) {
      this.getEmployeesWithoutPagination.forEach(element => {
        this.employeeOptions.push({
          id: element.id,
          name: element.full_name,
        })
      })
    }
  },

  created() {
    this.getEmployeesWallet.data != null
      ? (this.rows = this.getEmployeesWallet.data)
      : null
  },

  methods: {
    ...mapActions('professionals', [
      'getEmployeesWalletAction',
      'filterEmployeesWalletAction',
      'deleteEmployeeAction',
      'downloadEmployeeContractAction',
      'getBdpSavedEmployeesAction',
      'loadMoreEmployeesWalletAction',
    ]),
    applyFilterEmployeePaymentAction() {
      const allIsNUl = Object.values(this.payloadFilter).every(
        el => el === '',
      )
      if (allIsNUl) {
        this.$toast(
          {
            component: ToastificationContent,
            props: {
              title: 'Warning',
              icon: 'CheckIcon',
              text: 'Veuillez sélectionner au moins un champ du filtre.',
              variant: 'warning',
            },
          },
          {
            position: 'top-center',
          },
        )
      } else {
        this.isFilterEmployeePayment = true
        this.rows = []
        this.filterEmployeesWalletAction(this.payloadFilter)
          .then(response => {
            this.isFilterEmployeePayment = false
            this.rows = this.getEmployeesWallet
          })
          .catch(error => {
            console.log(error)
            this.isFilterEmployeePayment = false
          })
      }
    },
    reLoadDataEmployeesWalletAction() {
      this.isReLoadDataEmployeesWallet = true
      this.$store.commit('SET_IS_EMPLOYEE_WALLET_LOADING', true)
      this.rows = []
      this.getEmployeesWalletAction()
        .then(response => {
          this.isReLoadDataEmployeesWallet = false
          this.$store.commit('SET_IS_EMPLOYEE_WALLET_LOADING', false)
        })
        .catch(error => {
          this.isReLoadDataEmployeesWallet = false
          this.$store.commit('SET_IS_EMPLOYEE_WALLET_LOADING', false)
        })
    },

    showEmployeeWalletDetails(wallet) {
      this.$router.push({
        name: 'admin-employees-wallet',
        params: { id: wallet.id },
      })
    },
    applyLoadMoreEmployeesWalletAction(url) {
      this.$store.commit('SET_IS_EMPLOYEE_WALLET_LOADING', true)
      this.rows = []
      this.loadMoreEmployeesWalletAction(url)
        .then(() => {
          this.$store.commit('SET_IS_EMPLOYEE_WALLET_LOADING', false)
        })
        .catch(() => {
          this.$store.commit('SET_IS_EMPLOYEE_WALLET_LOADING', false)
        })
    },
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}

.invoice-filter-select {
  min-width: 190px;

  ::v-deep .vs__selected-options {
    flex-wrap: nowrap;
  }

  ::v-deep .vs__selected {
    width: 100px;
  }
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-good-table.scss";
</style>
